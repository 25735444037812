import React, { useEffect, useRef, useState } from "react";
import AdminVideoPlayer from "../video/AdminVideoPlayer";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LaunchIcon from "@mui/icons-material/Launch";
import Logo from "@/assets/logo.png";
import { AgoraWebClient } from "@/utils/agora-web-client";

const DashBoardStream = ({
  liveStreams,
  eventRoomStoreLocal,
  VisitDetails,
}: any) => {
  const webClient = eventRoomStoreLocal.rtcClient as AgoraWebClient;
  const streamsToPlayOnClick = useRef<any[]>([]);
  const [streamNumber, setmainStreamNumber] = useState(0);
  const [streams, setStreams] = useState<any>({
    mainStreams: [],
    injectedStreams: [],
  });

  useEffect(() => {
    // Filter out streams where userRole is 'host'
    const filteredMainStreams = liveStreams.mainStreams.filter(
      (stream: any) => stream.userRole !== 'host'
    );
    setStreams({ ...liveStreams, mainStreams: filteredMainStreams });
  }, [liveStreams]);

  const handleFailedStreams = (stream: any) => {
    streamsToPlayOnClick.current.push(stream);
  };

  const mainStreamHandler = (type: "increase" | "decrease") => {
    let numberOfStreams = streams.mainStreams.length;
    let newStreamNumber = streamNumber;

    if (type === "increase") {
      while (newStreamNumber < numberOfStreams - 1) {
        newStreamNumber++;
        if (streams.mainStreams[newStreamNumber]?.userRole !== "host") {
          break;
        }
      }
    } else if (type === "decrease") {
      while (newStreamNumber > 0) {
        newStreamNumber--;
        if (streams.mainStreams[newStreamNumber]?.userRole !== "host") {
          break; // Found a valid stream
        }
      }
    }

    if (newStreamNumber !== streamNumber) {
      setmainStreamNumber(newStreamNumber);
    }
  };

  webClient.rtc.on("user-left", ([user, reason]) => {
    if (streamNumber >= 1) {
      setmainStreamNumber(streamNumber - 1);
    }
  });

  return (
    <>
      {liveStreams.mainStreams[streamNumber] !== undefined ? (
        <div className="main-stream">
          <AdminVideoPlayer
            eventRoomStoreLocal={eventRoomStoreLocal}
            role={liveStreams.mainStreams[streamNumber]?.userRole}
            streamID={streams.mainStreams[streamNumber]?.streamID}
            stream={streams.mainStreams[streamNumber]?.stream}
            domId={`${streams.mainStreams[streamNumber]?.streamID}`}
            id={`${streams.mainStreams[streamNumber]?.streamID}`}
            video={
              typeof streams.mainStreams[streamNumber]?.stream?.videoTrack ==
                "undefined" ||
              streams.mainStreams[streamNumber]?.stream?.videoTrack == null
                ? false
                : streams.mainStreams[streamNumber]?.video
            }
            audio={
              typeof streams.mainStreams[streamNumber]?.stream?.audioTrack ==
                "undefined" ||
              streams.mainStreams[streamNumber]?.stream?.audioTrack == null
                ? false
                : streams.mainStreams[streamNumber]?.audio
            }
            local={streams.mainStreams[streamNumber]?.local}
            autoplay={true}
            onAutoPlayFailed={handleFailedStreams}
            mainSt={streams.mainStreams[streamNumber]}
            name={streams.mainStreams[streamNumber]?.name}
            liveStreams={liveStreams}
          />
        </div>
      ) : (
        <>
          {VisitDetails.visit_picture ? (
            <img src={VisitDetails.visit_picture} alt="event-image" />
          ) : (
            <img src={Logo} alt="event-image" />
          )}
        </>
      )}
      {/* <div
        className="event-redirection"
        onClick={() => {
          window.location.href = VisitDetails.admin_link;
        }}
      >
        <LaunchIcon fontSize="small" />
      </div> */}
      <div className="event-control">
        <span className="event-left">
          {streamNumber !== 0 && (
            <KeyboardArrowLeft
              onClick={() => {
                mainStreamHandler("decrease");
              }}
            />
          )}
        </span>
        <span className="event-right">
          {eventRoomStoreLocal.state.rtc.remoteStreams.size > 1 &&
            eventRoomStoreLocal.state.rtc.remoteStreams.size - 1 !==
              streamNumber && (
              <KeyboardArrowRightIcon
                onClick={() => {
                  mainStreamHandler("increase");
                }}
              />
            )}
        </span>
      </div>
    </>
  );
};
export default DashBoardStream;
