import React, { useEffect, useRef } from 'react';
import {historyStore} from '../stores/history';
import { GlobalState, globalStore} from '../stores/global';
import { EventRoomState, eventRoomStore} from '../stores/event';
import {ErrorState, errorStore} from '../pages/error-page/state';

import { useHistory, useLocation } from 'react-router-dom';


export type IRootProvider = {
  globalState: GlobalState
  eventRoomState: EventRoomState
  errorState: ErrorState
  historyState: any
}

export interface IObserver<T> {
  subscribe: (setState: (state: T) => void) => void
  unsubscribe: () => void
  defaultState: T
}

export function useObserver<T>(store: IObserver<T>) {
  const [state, setState] = React.useState<T>(store.defaultState);
  React.useEffect(() => {
    store.subscribe((state: any) => {
      setState(state);
    });
    return () => {
      store.unsubscribe();
    };
  }, [store]);

  return state;
}


export const RootContext = React.createContext({} as IRootProvider);

export const useStore = () => {
  const context = React.useContext(RootContext)
  if (context === undefined) {
    throw new Error('useStore must be used within a RootProvider');
  }
  
  return context;
}

export const useGlobalState = () => {
  return useStore().globalState;
}

export const useEventRoomState = () => {
  return useStore().eventRoomState;
}

export const useErrorState = () => {
  return useStore().errorState;
}


export const RootProvider: React.FC<any> = ({children}) => {
  const globalState = useObserver<GlobalState>(globalStore);
  const eventRoomState = useObserver<EventRoomState>(eventRoomStore);
  const errorState = useObserver<ErrorState>(errorStore);
  const historyState = useObserver<any>(historyStore);
  const history = useHistory();

  const ref = useRef<boolean>(false);

  useEffect(() => {
    return () => {
      ref.current = true;
    }
  }, []);

  const value = {
    globalState,
    eventRoomState,
    errorState,
    historyState,
  }

  useEffect(() => {
    historyStore.setHistory(history)
  }, [])



  //const location = useLocation();
  const location = window.location;

  useEffect(() => {
    if (location.pathname === '/') {
      return;
    }

    // TODO: Please remove it before release in production
    // 备注：请在正式发布时删除操作的window属性
    //@ts-ignore
    window.errorState = errorState;
  
    //@ts-ignore
    window.globalState = globalState;
  }, [value, location]);
  return (
    <RootContext.Provider value={value}>
      {children}
    </RootContext.Provider>
  )
}