import { eventRoomStore } from "@/stores/event";
import AgoraRTM from "agora-rtm-sdk";
import EventEmitter from "events";
import * as _ from "lodash";
import { toast } from "react-toastify";

export const APP_ID = process.env.REACT_APP_AGORA_APP_ID as string;
const ENABLE_LOG = process.env.REACT_APP_AGORA_LOG === "true";
const logFilter = ENABLE_LOG
  ? AgoraRTM.LOG_FILTER_DEBUG
  : AgoraRTM.LOG_FILTER_OFF;

export enum ChatCmdType {
  chat = 1,
  muteAudio = 101,
  unmuteAudio = 102,
  muteVideo = 103,
  unmuteVideo = 104,
  pinid = 105,
  isSilent = 106,
  remoteUsersRemove = 107,
}

export interface ChannelBodyParams {
  account: string;
  recordId: string;
  content: string;
}

export interface ChannelParams {
  type: number;
  msgData: any;
}

export interface NotifyMessageParams {
  cmd: ChatCmdType;
  data: ChatMessage | UserMessage;
  enableHistoricalMessaging?: boolean;
}

export type ChatMessage = {
  account: string;
  content: string;
  userId?: string;
};

export interface UserMessage {
  uid: string;
  account: string;
  resource: string;
  value: number;
}

export interface ChatBody {
  account: string;
  content: string;
}

export interface PeerMessage {
  uid: string;
  userId: string;
  account: string;
  operate: number;
}

export interface MessageBody {
  cmd?: ChatCmdType;
  text?: string;
  data?: ChatMessage | UserMessage | PeerMessage;
  type?: number;
  msgData?: any;
}

export default class AgoraRTMClient {
  private _bus: EventEmitter;
  public _currentChannel: any;
  public _currentChannelName: string | any;
  private _channels: any;
  public _client: any;
  private _channelAttrsKey: string | any;
  public _logged: boolean = false;
  private _joined: boolean = false;

  constructor() {
    this._bus = new EventEmitter();
    this._channels = {};
    this._currentChannel = null;
    this._currentChannelName = null;
    this._channelAttrsKey = null;
    this._client = null;
  }

  public removeAllListeners(): any {
    this._bus.removeAllListeners();
  }

  destroy(): void {
    for (let channel of Object.keys(this._channels)) {
      if (this._channels[channel]) {
        this._channels[channel].removeAllListeners();
        this._channels[channel] = null;
      }
    }
    this._currentChannel = null;
    this._currentChannelName = null;
    this._client.removeAllListeners();
  }

  on(evtName: string, cb: (args: any) => void) {
    this._bus.on(evtName, cb);
  }

  off(evtName: string, cb: (args: any) => void) {
    this._bus.off(evtName, cb);
  }

  async login(appID: string, uid: string, token?: string) {
    const { RTM } = AgoraRTM;
    const appId = appID;
    const userId = uid;
    const rtm = new RTM(appId, userId, { token });
    try {
      const result = await rtm.login();
      this._client = rtm;
      this._logged = true;
      console.log(this._client, this._logged, "rtm-status-login");
      this._client.addEventListener("message", (event: any) => {
        console.log(event, "rtm-status-console");
        let chatMessage = JSON.parse(event.message);
        console.log("hello chatMessage", chatMessage);
        if (chatMessage.cmd === ChatCmdType.chat) {
          eventRoomStore._state.isMessageSent = true;
          console.log("console inside chat messaage");
          if (chatMessage?.data.type == "Private") {
            eventRoomStore.updatePrivateChatMessage(chatMessage.data);
            toast.success("New Private Message Received!", {
              position: "bottom-right",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                backgroundColor: "#DC6E65",
                color: "#FFFFFF",
              },
            });
          } else {
            eventRoomStore.updateChatMessage(chatMessage.data);
            if (
              eventRoomStore._state.me.info.role !== "invitee"
            ) {
              toast("Nouveau message", {
                // position: "bottom-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                  backgroundColor: "#DC6E65",
                  color: "#FFFFFF",
                },
              });
            }
          }
        }
        if (chatMessage.cmd == ChatCmdType.muteAudio) {
          eventRoomStore.mute(eventRoomStore.state.me.uid.toString(), "audio");
        }
        if (chatMessage.cmd === ChatCmdType.unmuteAudio) {
          eventRoomStore.unmute(
            eventRoomStore.state.me.uid.toString(),
            "audio"
          );
        }
        if (chatMessage.cmd === ChatCmdType.muteVideo) {

          eventRoomStore.mute(eventRoomStore.state.me.uid.toString(), "video");
        }
        if (chatMessage.cmd === ChatCmdType.unmuteVideo) {
          eventRoomStore.unmute(
            eventRoomStore.state.me.uid.toString(),
            "video"
          );
        }
        if (chatMessage.cmd === ChatCmdType.isSilent) {
          console.log("Is Silent Feature Received");
        }
        if (chatMessage.cmd === ChatCmdType.remoteUsersRemove) {
          console.log("hello remove", chatMessage, event);
          // if(eventRoomStore._state.me.info.uid == chatMessage.data){
          sessionStorage.removeItem("visitStarted");
          window.location.href = "/end";
          // }
        }
        if (chatMessage.cmd === ChatCmdType.pinid) {
          eventRoomStore.setPinId(chatMessage.data.streamID);
        }
      });
      this._client.addEventListener("presence", (event: any) => {
        console.log(event, "rtm-status-presence");
        const action = event.eventType; // The action. Should be one of 'SNAPSHOT'、'INTERVAL'、'JOIN'、'LEAVE'、'TIMEOUT、'STATE_CHANGED'、'OUT_OF_SERVICE'.
        const channelType = event.channelType; // The channel type. Should be "STREAM" or "MESSAGE" .
        const channelName = event.channelName; // The channel this event came from
        const publisher = event.publisher; // Who triggered this event
        const states = event.stateChanged; // User state payload
        const interval = event.interval; // Interval payload
        const snapshot = event.snapshot; // Snapshot payload
        // const currentNetworkQuality = eventRoomStore._state.networkQuality;
        // console.log(`Publisher ${publisher} has poor network quality: ${currentNetworkQuality}`);
        eventRoomStore.getMembersList(eventRoomStore.state.me.channelName);
      });
      // Topic
      this._client.addEventListener("topic", (event: any) => {
        console.log(event, "rtm-status-topic");
        const action = event.evenType; // The action. Should be one of 'SNAPSHOT'、'JOIN'、'LEAVE'.
        const channelName = event.channelName; // The channel this event came from
        const publisher = event.userId; // Who triggered this event
        const topicInfos = event.topicInfos; // Topic information payload
        const totalTopics = event.totalTopics; // How many topics
      });
      // Storage
      this._client.addEventListener("storage", (event: any) => {
        console.log(event, "rtm-status-storage");
        const channelType = event.channelType; // The channel type. Should be "STREAM" or "MESSAGE" .
        const channelName = event.channelName; // The channel this event came from
        const publisher = event.publisher; // Who triggered this event
        const storageType = event.storageType; // Which category the event is, should be 'USER'、'CHANNEL'
        const action = event.eventType; // The action. Should be one of "SNAPSHOT"、"SET"、"REMOVE"、"UPDATE" or "NONE"
        const data = event.data; // 'USER_METADATA' or 'CHANNEL_METADATA' payload
      });
      // Lock
      this._client.addEventListener("lock", (event: any) => {
        console.log(event, "rtm-status-lock");
        const channelType = event.channelType; // The channel type. Should be "STREAM" or "MESSAGE" .
        const channelName = event.channelName; // The channel this event came from
        const publisher = event.publisher; // Who triggered this event
        const action = event.evenType; // The action. Should be one of 'SET'、'REMOVED'、'ACQUIRED'、'RELEASED'、'EXPIRED'、'SNAPSHOT'
        const lockName = event.lockName; // Which lock it affected
        const ttl = event.ttl; // The ttl of this lock
        const snapshot = event.snapshot; // Snapshot payload
      });
      // Connection State Change
      this._client.addEventListener("status", (event: any) => {
        console.log(event, "rtm-status-status");
        const currentState = event.state; // The current connection state
        const changeReason = event.reason; // Why this event was triggered
      });
    } catch (err) {
      console.log(err, "rtm-status-error");
      this._client.removeAllListeners();
      throw err;
    }
  }

  async logout() {
    if (!this._logged) return;
    await this._client.logout();
    this.destroy();
    this._logged = false;
    return;
  }

  setUserExtraAttributes(userName: any) {
    this._client.addOrUpdateLocalUserAttributes({ name: userName });
  }

  getUserExtraAttributes(userId: any) {
    //return this._client.getUserAttributes(userId);
    return this._client.getUserAttributesByKeys(userId, ["name"]);
  }

  async join(channel: string) {
    try {
      const subscribeOptions = {
        withMessage: true,
        withPresence: true,
        withMetadata: true,
        withLock: true,
      };
      await this._client.subscribe(channel, subscribeOptions);
      await this._client.subscribe(
        `inbox_${eventRoomStore._state.me.info.uid}`,
        subscribeOptions
      );
    } catch (error) {
      console.log(error);
    }
    this._joined = true;
    return;
  }

  destroyChannel(channel: string) {
    if (this._channels[channel]) {
      this._channels[channel].removeAllListeners();
      this._channels[channel] = null;
    }
  }

  async leave(channel: string) {
    if (this._channels[channel]) {
      // await this._channels[channel].leave();
      this._joined = false;
      this.destroyChannel(channel);
    }
  }

  async exit() {
    try {
      await this.deleteChannelAttributesByKey();
    } catch (err) {
    } finally {
      await this.leave(this._currentChannelName);
      await this.logout();
    }
  }

  async getChannelMembersList(channel: string): Promise<string[]> {
    const result = await this._client.presence.getOnlineUsers(
      channel,
      "MESSAGE"
    );
    console.log("rtm-status-result", result);
    return result.occupants;
    // if (this._channels[channel]) {
    // return await this._channels[channel].getMembers();
    // }
    // return [];
  }

  async notifyMessage(params: NotifyMessageParams, chanelName: any) {
    const { cmd, data, enableHistoricalMessaging = false } = params;

    const body = JSON.stringify({
      cmd,
      data,
    });
    // );
    console.log("parama", params);
    const payload = params;
    const publishMessage = JSON.stringify(params);
    try {
      return await this._client.publish(chanelName, publishMessage);
      // console.log(result, "rtm-status-chanelMessage");
    } catch (status) {
      console.log(status, "rtm-status-chanelMessage-error");
    }
  }

  async sendPeerMessage(peerId: string, body: MessageBody, channelName: any) {
    console.log("rtm-status-sendPeerMessage", peerId, body, channelName);
    const payload = {
      cmd: body.type,
      data: body?.msgData,
      enableHistoricalMessaging: true,
    };
    
    console.log("rtm-status-Id", JSON.stringify(payload));
    try {
      // Send a private message to the user with the UserID "Lily"
      let finaldata = JSON.stringify(payload);
      let sendingId = `inbox_${peerId}`;
      const result = await this._client.publish(sendingId, finaldata);
      console.log(result);
    } catch (status) {
      console.log(status);
    }
    // return result.hasPeerReceived;
  }

  async sendChannelMessage(data: ChannelParams, chanelName: any) {
    return this.notifyMessage(
      {
        cmd: data.type,
        data: data.msgData,
        enableHistoricalMessaging: true,
      },
      chanelName
    );
  }

  async updateChannelAttrsByKey(key: string, attrs: any) {
    this._channelAttrsKey = key;
    const channelAttributes: { [key: string]: string } = {};
    if (key) {
      channelAttributes[key] = JSON.stringify(attrs);
    }
    await this._client.addOrUpdateChannelAttributes(
      this._currentChannelName,
      channelAttributes,
      { enableNotificationToChannelMembers: true }
    );
  }

  async deleteChannelAttributesByKey() {
    if (!this._channelAttrsKey) return;
    await this._client.deleteChannelAttributesByKeys(
      this._currentChannelName,
      [this._channelAttrsKey],
      { enableNotificationToChannelMembers: true }
    );
    this._channelAttrsKey = null;
    return;
  }

  async getChannelAttrs(): Promise<string> {
    let json = await this._client.getChannelAttributes(
      this._currentChannelName
    );
    return JSON.stringify(json);
  }

  async getChannelMemberCount(ids: string[]) {
    const options = {
      includeUserId: true,
      includeState: true,
      page: "yourBookMark",
    };
    console.log("rtm-status-ids", ids);
    try {
      console.log("rtm-status-result-before");
      const result = await this._client.presence.getOnlineUsers(
        ids[0],
        "MESSAGE"
      );
      return result.totalOccupancy;
      console.log("rtm-status-result", result);
    } catch (status) {
      console.log("rtm-status-result-error", status);
      console.log(status);
    }
    // return this._client.getChannelMemberCount(ids);
  }
}

export const agoraRTMClient = new AgoraRTMClient();