import React, { useEffect, useRef, useState } from "react";
import "./HostDashBoard.scss";
import { PageHeader } from "@/components/header/header";
import Footer from "../Footer/Footer";
import { DashBoardLayout } from "./dashboard-layout/DashBoardLayout";
import { useParams } from "react-router-dom";
import { eventRoomStore } from "@/stores/event";
import { toast } from "react-toastify";
import WelcomeLoader from "../WelcomeLoader/WelcomeLoader";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const HostDashBoard = () => {
  const params = useParams<any>();
  const UserId = params.userId.replace("&limit=4&page=1", "");
  const isLoader = useRef<boolean>(true);
  const [eventDetailsData, seteventDetailsData] = useState<any[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<any[]>([]);
  const [currentpage, setcurrentpage] = useState<number>(1);
  const [itemsPerPage, setitemsPerPage] = useState<number>(4);
  const [totalPosts, setTotalPosts] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getVisitDetails = async (page: number) => {
    setIsLoading(true);
    try {
      const visitDetails: any = await eventRoomStore.fetchadminvisitDetails(UserId, itemsPerPage, page);
      console.log("Visit details:", visitDetails);
      if (visitDetails.status === 200) {
        setTotalPosts(visitDetails.total);
        isLoader.current = false;
        const today = new Date().toISOString().split('T')[0];
        const filtered = visitDetails.visit.filter((event: any) => new Date(event.data.time_in_utc).toISOString().split('T')[0] === today);
        setFilteredEvents(filtered);
        seteventDetailsData(visitDetails.visit);
        setError(null);
      } else if (visitDetails.status === 404) {
        toast.error("No Visit Details found");
        window.location.href = "/404";
      } else {
        toast.error("Error fetching Visit Details");
      }
    } catch (err) {
      toast.error("An unexpected error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const savedPage = sessionStorage.getItem('currentpage');
    if (savedPage) {
      setcurrentpage(parseInt(savedPage, 10));
    }
  }, []);

  useEffect(() => {
    getVisitDetails(currentpage);
    sessionStorage.setItem('currentpage', currentpage.toString());
  }, [currentpage, itemsPerPage]);

  useEffect(() => {
    console.log("eventDetailsData", eventDetailsData);
  }, [eventDetailsData]);

  useEffect(() => {
    console.log("Current Page After Update:", currentpage);
  }, [currentpage]);

  const totalPages = Math.ceil(totalPosts / itemsPerPage);

  const pageHandler = (type: "forward" | "backward") => {
    if (type === "forward" && currentpage < totalPages) {
      console.log("Current Page Before Update (Forward):", currentpage);
      setcurrentpage((prevPage) => {
        const newPage = prevPage + 1;
        console.log("Next Page (Forward):", newPage);
        return newPage;
      });
    }
    if (type === "backward" && currentpage > 1) {
      console.log("Current Page Before Update (Backward):", currentpage);
      setcurrentpage((prevPage) => {
        const newPage = prevPage - 1;
        console.log("Next Page (Backward):", newPage);
        return newPage;
      });
    }
  };

  return (
    <div className="host-dashboard-page">
      <PageHeader ShowOffer={true} Showcontact={true} />
      {error && <div>{error}</div>}
      <div className="dashboard-events">
        {isLoading ? (
          <WelcomeLoader />
        ) : (
          <div className="dashboard-events-content">
            {filteredEvents.length > 0 ? (
              <>
                {filteredEvents.map((event: any, index: any) => (
                  <DashBoardLayout key={event.data.id} data={event.data} />
                ))}
                {totalPosts > itemsPerPage && (
                  <div className="pagination">
                    {currentpage > 1 && (
                      <button onClick={() => pageHandler("backward")}>
                        <ArrowBackIcon />
                      </button>
                    )}
                    {currentpage < totalPages && (
                      <button onClick={() => pageHandler("forward")}>
                        <ArrowForwardIcon />
                      </button>
                    )}
                  </div>
                )}
              </>
            ) : (
              <div className="dashboard-events-content-error">
                No Visit is Scheduled right now
                {totalPosts > itemsPerPage && (
                  <div className="pagination">
                    {currentpage > 1 && (
                      <button onClick={() => pageHandler("backward")}>
                        <ArrowBackIcon />
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};
