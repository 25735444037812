import React, { useState, useEffect, useCallback, useRef } from "react";
import "./PreCallScreen.scss";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import MicIcon from "@mui/icons-material/Mic";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VoiceVolume from "@/components/preview-stream/voice";
import { eventRoomStore } from "@/stores/event";
import { AgoraWebClient } from "@/utils/agora-web-client";
import AgoraRTC from "agora-rtc-sdk-ng";
import WelcomeLoader from "../WelcomeLoader/WelcomeLoader";
import Logo from "@/assets/logo.png";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import AccessAllowedError from "../../components/access-allowed/error";
import PreCallScreenPopUp from "./PrecallPopup";
import { isIOS } from "react-device-detect";
import useSocket from "@/hooks/useSocket";
import { ESocketEvents } from "@/utils/api/sockets.enum";
import { useEventRoomState } from "@/containers/root-container";
import PersonIcon from "@mui/icons-material/Person";
import { toast } from "react-toastify";

interface PrecallScreenProps {
  JoinHandle?: any;
}

const PreCallScreen: React.FC<PrecallScreenProps> = ({ JoinHandle }) => {
  const [inputError, setInputError] = useState<string | null>(null);
const [inputBorderColor, setInputBorderColor] = useState<boolean>(false);
  const [askingCall, setAskingCall] = useState(false);
  const selectedCam = useRef<string>();
  const selectedAud = useRef<string>();
  const selectedSpeaker = useRef<string>();
  const isRecording = useRef<any>(true);
  const [devicesList, setDevicesList] = useState<any>({
    cameraDevices: [],
    microphoneDevices: [],
    speakerDevices: [],
  });
  const [tmpStream, setTmpStream] = useState<any>();
  const [volume, setVolume] = useState<number>(0);
  const [isLoader, setisLoader] = useState<boolean>(true);
  const [accessAllowed, setAccessAllowed] = useState<boolean>(true);
  const [isDeviceErr, setIsDeviceErr] = useState<boolean>(false);
  const deviceLoaded = useRef<boolean>(false);
  const { emitEvent } = useSocket();
  const eventRoomState = useEventRoomState();

  const [multiConnectionName, setMultiConnectionName] = useState<string>(
    sessionStorage.getItem("username") || ""
  );

  // Update the name in sessionStorage whenever it changes
  useEffect(() => {
    sessionStorage.setItem("username", multiConnectionName);
  }, [multiConnectionName]);

  const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMultiConnectionName(e.target.value);
  };


  useEffect(() => {
    if (eventRoomStore._state.me.info.role && isDeviceErr) {
      eventRoomStore.handleDeviceError();
    }
  }, [eventRoomStore._state.me, isDeviceErr]);

  const getDevicesList = async () => {
    const webClient = eventRoomStore.rtcClient as AgoraWebClient;
    let devices = await webClient.getDevices();
    let camDevices = devices.filter((x: any) => x.kind === "videoinput");
    const audioDevices = devices.filter((x: any) => x.kind === "audioinput");
    const audioOutDevices = devices.filter(
      (x: any) => x.kind === "audiooutput"
    );
    if (isIOS && eventRoomStore._state.me.info.role == "guide") {
      const frontCameras = camDevices.filter((cam: any) =>
        cam.label.toString().toLowerCase().includes("front")
      );
      const backCameras = camDevices.filter((cam: any) =>
        cam.label.toString().toLowerCase().includes("back")
      );
      camDevices = [];
      if (frontCameras.length) {
        camDevices.push(frontCameras[0]);
      }
      if (backCameras.length) {
        camDevices.push(backCameras[0]);
      }
    }
    deviceLoaded.current = true;
    setDevicesList({
      cameraDevices: camDevices,
      microphoneDevices: audioDevices,
      speakerDevices: audioOutDevices,
    });
  };
  useEffect(() => {
    async function setTmpPrevStream() {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(function () {
          console.log("Permission to access  is granted.");
        })
        .catch(function (error) {
          setAccessAllowed(false);
          console.log("Permission to access  is not granted:", error);
        });

      await getDevicesList();
    }
    setTmpPrevStream();
  }, [AgoraRTC.getDevices]);

  useEffect(() => {
    async function handleTmpStream() {
      if (devicesList.cameraDevices.length) {
        const selectedMicrophoneId = "";
        const selectedCameraId = "";
        let audioTrack = null as any;
        let videoTrack = "";
        try {
          videoTrack = await AgoraRTC.createCameraVideoTrack({
            optimizationMode: "motion",
            cameraId: selectedCameraId,
          });
          audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
            microphoneId: selectedMicrophoneId,
            AEC: true, // acoustic echo cancellation
            AGC: true, // audio gain control
            ANS: true, // automatic noise suppression
            encoderConfig: "speech_standard",
          });
          setTmpStream({ audioTrack, videoTrack });
        } catch (e) {
          setIsDeviceErr(true);
        }
      } else {
        setIsDeviceErr(true);
      }
      setisLoader(false);
    }
    if (deviceLoaded.current) {
      handleTmpStream();
    }
  }, [devicesList]);

  useEffect(() => {
    let setAudInterval: any;
    if (tmpStream) {
      tmpStream.videoTrack.play("local-preview");
      if (tmpStream?.audioTrack) {
        setAudInterval = setInterval(function () {
          setVolume(tmpStream.audioTrack.getVolumeLevel());
        }, 1000);
      }
    }
    return () => {
      if (tmpStream) {
        if (tmpStream?.videoTrack) {
          tmpStream.videoTrack.stop();
          tmpStream.videoTrack.close();
        }
        if (tmpStream?.audioTrack) {
          tmpStream.audioTrack.stop();
          tmpStream.audioTrack.close();
        }
      }
      clearInterval(setAudInterval);
    };
  }, [tmpStream]);

  const setCameraDevice = (e: any) => {
    tmpStream.videoTrack.setDevice(e.target.value);
    selectedCam.current = e.target.value;
  };

  const setAudioDevice = (e: any) => {
    tmpStream.audioTrack.setDevice(e.target.value);
    selectedAud.current = e.target.value;
  };

  const setSpeakerDevice = (e: any) => {
    selectedSpeaker.current = e.target.value;
  };
  const setRecording = (e: any) => {
    isRecording.current = e.target.value;
  };

  const setLocalStreamDevices = () => {
    if (devicesList.cameraDevices.length == 0) {
      eventRoomStore.handleDeviceError();
      return;
    }
    window.sessionStorage.setItem(
      "cameraId",
      selectedCam.current || devicesList?.cameraDevices?.[0]?.deviceId || ""
    );
    window.sessionStorage.setItem(
      "microphoneId",
      selectedAud.current || devicesList?.microphoneDevices?.[0].deviceId || ""
    );
    {
      devicesList?.speakerDevices.length > 0 &&
        window.sessionStorage.setItem(
          "speakerId",
          selectedSpeaker.current || devicesList?.speakerDevices[0].deviceId
        );
    }
    sessionStorage.setItem("recording", isRecording.current);

    JoinHandle();
  };

  const ResidenceJoinHandler = async () => {
    if (eventRoomStore._state.me.info.role === "multiconnection") {
      if (!multiConnectionName.trim()) {
        setInputError("Veuillez renseigner votre nom");
        setInputBorderColor(true);
        return;
      } else {
        await eventRoomStore.SubmitParticipantdetails(
          eventRoomState.me.channelName,
          // eventRoomState.me.info.name,
          multiConnectionName,
          eventRoomState.me.info.uid,
          eventRoomState.me.info.role
        );
      }
    }
    await eventRoomStore.SubmitParticipantdetails(
      eventRoomState.me.channelName,
      eventRoomState.me.info.name,
      eventRoomState.me.info.uid,
      eventRoomState.me.info.role
    )
    emitEvent(ESocketEvents.ACCESS_WAIT, {
      visit_id: eventRoomState.me.channelName,
      user_id: eventRoomState.me.info.uid,
      user_name: eventRoomState.me.info.name,
      user_role: eventRoomState.me.info.role,
    });
    // if (eventRoomState.me.info.role == "multiconnection") {
    //   sessionStorage.setItem("username", multiConnectionName.current);
    // } else {
    //   sessionStorage.setItem("username", eventRoomState.me.info.name);
    // }
    // sessionStorage.setItem("userId", eventRoomState.me.info.uid);
    // sessionStorage.setItem("userole", eventRoomState.me.info.role);
    // sessionStorage.setItem("visit_id", eventRoomState.me.channelName);
    setAskingCall(!askingCall);
  };
  const MicrophoneVolume = useCallback(() => {
    return <VoiceVolume volume={volume} />;
  }, [volume]);

  return (
    <>
      <div className="precall-screen">
        <div className="precall-screen-logo">
          <img src={Logo} />
        </div>
        {!accessAllowed ? (
          <AccessAllowedError />
        ) : (
          <div
            // className={
            //   eventRoomStore._state.me.info.role === "residence"
            //     ? "precall-screen-content residence"
            //     : "precall-screen-content"
            // }
            className="precall-screen-content"
          >
            <div id="local-preview" className="local-stream-preview">
              {(eventRoomStore._state.me.info.role === "residence" ||
                eventRoomStore._state.me.info.role !== "multiconnection") && (
                <div className="local-stream-heading">
                  <p>
                    Assurez vous que la caméra est correctement positionnée afin
                    de bien voir les participants
                  </p>
                </div>
              )}
            </div>
            {isLoader ? (
              <WelcomeLoader />
            ) : !askingCall ? (
              <div className={eventRoomState.me.info.role === "multiconnection" ? "precall-screen-options multiconnection" : "precall-screen-options"}>
                {eventRoomStore._state.me.info.role == "guide" && (
                  <PreCallScreenPopUp />
                )}
                <div className="precall-screen-text">
                  {eventRoomStore._state.me.info.role == "guide"
                    ? "Please select your device"
                    : "Sélectionnez votre appareil"}
                </div>
                {eventRoomStore._state.me.info.role === "multiconnection" && (
                  <div
                    className="precall-screen-option"
                    style={{
                      position: "relative",
                      display: "flex",
                      margin: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <label>
                      <PersonIcon />
                    </label>
                    <input
                      className={inputBorderColor ? "mlt-input-error" : ""}
                      style={{
                        width: "calc(100% - 3rem)",
                        padding: "1rem",
                        outline: "none",
                        borderWidth: inputBorderColor ? 2 : 1,
                        borderRadius: "0.5rem",
                        boxSizing: "border-box",
                        fontSize: inputError ? "1.8rem" : (eventRoomStore._state.me.info.role === "multiconnection" ? "1.4rem" : "1rem"),
                        borderColor: inputBorderColor ? "#b65a52" : "#000",
                      }}
                      type="text"
                      placeholder={inputError || "Saisissez votre prénom"}
                      value={multiConnectionName}
                      onChange={handleUserNameChange}
                    />
                    {inputError &&
                      <span className={inputError ? "mlt-input-error-box" : ""}>
                        Veuillez renseigner ce champ
                      </span>
                    }
                  </div>
                )}
                {devicesList?.cameraDevices.length > 0 && (
                  <div className="precall-screen-option">
                    <label>
                      <CameraAltIcon />
                    </label>
                    <select
                      onChange={setCameraDevice}
                      className="precall-screen-select"
                    >
                      {devicesList?.cameraDevices.map(
                        (device: any, index: any) => (
                          <option
                            value={device.deviceId}
                            key={index}
                            onSelect={setCameraDevice}
                          >
                            {device.label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                )}
                <div className="precall-screen-option">
                  <label>
                    <MicIcon />
                  </label>
                  <select
                    onChange={setAudioDevice}
                    className="precall-screen-select"
                  >
                    {devicesList?.microphoneDevices.map(
                      (device: any, index: any) => (
                        <option
                          value={device.deviceId}
                          key={index}
                          onSelect={setAudioDevice}
                        >
                          {device.label}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <MicrophoneVolume />
                {devicesList?.speakerDevices.length > 0 && (
                  <div className="precall-screen-option">
                    <label>
                      <VolumeUpIcon />
                    </label>
                    <select
                      onChange={setSpeakerDevice}
                      className="precall-screen-select"
                    >
                      {devicesList?.speakerDevices.map(
                        (device: any, index: any) => (
                          <option
                            value={device.deviceId}
                            key={index}
                            onSelect={setSpeakerDevice}
                          >
                            {device.label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                )}
                {/* {eventRoomStore._state.me.info.role == "host" && (
                  <div className="precall-screen-option">
                    <label>
                      <GraphicEqIcon />
                    </label>
                    <select
                      onChange={setRecording}
                      className="precall-screen-select"
                    >
                      <option value={"false"} onSelect={setRecording}>
                        False
                      </option>
                      <option value={"true"} onSelect={setRecording}>
                        True
                      </option>
                    </select>
                  </div>
                )} */}
                {eventRoomStore._state.me.info.role === "residence" ||
                eventRoomStore._state.me.info.role === "multiconnection" ? (
                  <button
                    onClick={ResidenceJoinHandler}
                    className="precall-screen-button"
                  >
                    Rejoindre la visite
                  </button>
                ) : (
                  <button
                    onClick={setLocalStreamDevices}
                    className="precall-screen-button"
                  >
                    {eventRoomStore._state.me.info.role == "guide"
                      ? "Join Now"
                      : "Rejoindre"}
                  </button>
                )}
              </div>
            ) : (
              <div className="joining-request">
                <h3>Votre voyage culturel va démarrer</h3>
                <p>
                  Veuillez patienter quelques instants, votre guide va bientôt
                  vous accueillir...
                </p>
              </div>
            )}
          </div>
        )}
        {/* {continueCall && (
          <div className="continue-prompt-overshadow">
            <div className="continue-prompt">
              <p>Do you want to continue?</p>
              <div className="action-buttons">
                <button
                  onClick={() => {
                    RequstHandlerClick();
                  }}
                  className="button-continue"
                >
                  Yes
                </button>
                <button
                  onClick={() => {
                    RequstHandlerClick(true);
                  }}
                  className="button-reject"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )} */}
      </div>
    </>
  );
};
export default PreCallScreen;
